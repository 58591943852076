<template>

  <div>
    <Navbar :nav-light="true" />
    <br><br><br>
    <section class="section">
    <div class="container">

        <form>
          <div class="row mt-4">
            <div class="col-md-6">
              <div class="form-group">
                <label>От кого имя</label>
                <div class="position-relative">
                  <user-icon class="fea icon-sm icons"></user-icon>
                  <input
                      name="name"
                      id="first"
                      type="text"
                      class="form-control pl-5"
                      placeholder="Имя :"
                      v-model="showEmail.from_name"
                  />
                </div>
              </div>
            </div>
            <!--end col-->
            <div class="col-md-6">
              <div class="form-group">
                <label>От кого email</label>
                <div class="position-relative">
                  <mail-icon class="fea icon-sm icons"></mail-icon>
                  <input
                      name="email"
                      id="emailbcc"
                      type="email"
                      class="form-control pl-5"
                      placeholder="Введите email :"
                      v-model="showEmail.from_email"
                  />
                </div>
              </div>
            </div>
            <!--end col-->
            <div class="col-md-6">
              <div class="form-group">
                <label>Кому Имя</label>
                <div class="position-relative">
                  <user-icon class="fea icon-sm icons"></user-icon>
                  <input
                      name="nameto"
                      id="nameto"
                      type="text"
                      class="form-control pl-5"
                      placeholder="Введите имя :"
                      v-model="showEmail.to_name"
                  />
                </div>
              </div>
            </div>
            <!--end col-->
            <div class="col-md-6">
              <div class="form-group">
                <label>Кому Email</label>
                <div class="position-relative">
                  <mail-icon class="fea icon-sm icons"></mail-icon>
                  <input
                      name="email"
                      id="emailfrom"
                      type="email"
                      class="form-control pl-5"
                      placeholder="Введите email :"
                      v-model="showEmail.to_email"
                  />
                </div>
              </div>
            </div>


            <div class="col-lg-12">
              <div class="form-group">
                <label>Шаблон</label>
                <select class="form-control custom-select" v-model="emailTemplates_Selected">
                  <option v-bind:value="x" v-for="x in emailTemplates_Options">{{x.value}}</option>
                </select>
              </div>
            </div>

            <div class="col-lg-12">
              <div class="form-group">
                <label>Тема письма</label>
                <div class="position-relative">
                  <bookmark-icon
                      class="fea icon-sm icons"
                  ></bookmark-icon>
                  <input
                      name="name"
                      id="subject"
                      type="text"
                      class="form-control pl-5"
                      placeholder="Тема :"
                      v-model="showEmail.subject"
                  />
                </div>
              </div>
            </div>


            <div class="col-lg-12">
              <div class="form-group">
                <label>Текст письма</label>
                <div class="position-relative">
                  <!--<message-circle-icon
                      class="fea icon-sm icons"
                  ></message-circle-icon>
                  <textarea
                      name="comments"
                      id="comments"
                      class="form-control pl-5"
                      placeholder="Текст :"
                      v-model="showEmail.emailBody_str"
                  ></textarea>
                  -->

                  <quill-editor
                      ref="editor"
                      v-model="showEmail.emailBody_str"
                      :options="editorOption"
                  />

                </div>
              </div>
            </div>




          </div>
          <!--end row-->
          <div class="row">
            <div class="col-sm-12">
              <input
                  type="submit"
                  id="submit"
                  name="send"
                  class="btn btn-primary"
                  value="Сохранить"
              />
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </form>
        <!--end form-->


      </div>

    </section>

    <Footer />

  </div>

</template>

<script>
import Navbar from "@/components/navbar0";
import Footer from "@/components/footer";
import EmailLine from "./EmailLine";

import { MessageCircleIcon, MailIcon,UserIcon,BookmarkIcon,
GitBranchIcon, SkipForwardIcon } from "vue-feather-icons";


import store from "@/store/store";

export default {
  name: "EmailToCandidates",
  components: {
    Navbar,
    Footer,
    EmailLine,
    MessageCircleIcon, MailIcon,UserIcon,BookmarkIcon,
    GitBranchIcon, SkipForwardIcon,
  },
  mounted() {
    this.getAllVacancies();
    this.loadTemplateList();
  },

  watch: {
    selectAllCheckbox: function (val) {
      if (this.resumes.length > 0) {

        for (let i = 0; i < this.resumes.length; i++) {
          if (val) {
            this.resumes[i].isSelected = true;
          } else {
            this.resumes[i].isSelected = false;
          }
        }
      }
    },
  },

  methods: {

    getAllVacancies() {

          console.log('inside get all vacancies in /email')
      this.input_save_vacancy.user_id = store.state.user_id;

      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/prod/vacancy/get-list?access_token=' + store.state.token,
        data: this.input_save_vacancy,
      }).then((res) => {
        this.jobFunnels = res.data.funnel_arr;
      });

    },

    getResumes(vacancy_id,funnel_stage_order_id) {
      this.resumes=[];
      console.log('inside get getResumes')
      this.input_save_vacancy.user_id = store.state.user_id;
      this.input_save_vacancy.vacancy_id = vacancy_id;
      this.input_save_vacancy.funnel_stage_order_id = funnel_stage_order_id;
      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/prod/vacancy/get-resumes-minimal?access_token=' + store.state.token,
        data: this.input_save_vacancy,
      }).then((res) => {
        this.resumes = res.data.resumes;
        console.log(this.resumes)

        /*
        var ev = document.createEvent('KeyboardEvent');
        ev.initKeyboardEvent(
            'keydown', true, true, window, false, false, false, false, 27, 0);
        //ev.initKeyboardEvent("keypress",27);

        ev.initKeyboardEvent("keypress",       // typeArg,
            true,             // canBubbleArg,
            true,             // cancelableArg,
            null,             // viewArg,  Specifies UIEvent.view. This value may be null.
            false,            // ctrlKeyArg,
            false,            // altKeyArg,
            false,            // shiftKeyArg,
            false,            // metaKeyArg,
            27,               // keyCodeArg,
            0);              // charCodeArg);
            document.body.dispatchEvent(ev);
         */

      });
      this.input_sql_apply.funnel_stage_order_id = funnel_stage_order_id;
      this.input_sql_apply.vacancy_id = vacancy_id;
    },

    loadTemplateList(){

      this.input_save_template.user_id = store.state.user_id;
      this.input_save_template.selectForm = 0;

      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/email/get-template-str?access_token=' + store.state.token,
        data: this.input_save_template,
      }).then((res) => {
        this.emailTemplates = res.data.arr;
        console.log('inside loadTemplateList')
        console.log(this.emailTemplates);

        if (this.emailTemplates.length>0) {
          this.emailTemplates_Options = [];

          for (let i = 0; i < this.emailTemplates.length; i++) {
            this.emailTemplates_Options.push({
              'id': this.emailTemplates[i].id,
              'value': this.emailTemplates[i].name
            })
          }
          this.emailTemplates_Selected = {
            'id': this.emailTemplates[0].id,
            'value': this.emailTemplates[0].name
          };
          console.log(this.emailTemplates_Options)
        }

      });

    },


    updateResume(e) {
      console.log(e);

      this.resumes[e.index].isSelected = e.isSelected

    },

    indexListen(e){
      this.input_sql_apply.resume_id = this.resumes[e].resume_id;

      this.input_sql_apply.user_id = store.state.user_id;


      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/email/get-email-to-send?access_token=' + store.state.token,
        data: this.input_sql_apply,
      }).then((res) => {
        console.log(res.data);
        this.showEmail = res.data;
      }).catch((err) => {
        console.error(err);
      });

    },

    applyEmailTemplate(template_id) {
      console.log('inside apply email template')

      this.input_sql_apply.user_id = store.state.user_id;
      this.input_sql_apply.template_id = template_id;
      this.input_sql_apply.updateFlag = 0;
      this.input_sql_apply.resumes = [];
      for (let i = 0; i < this.resumes.length; i++) {
        if (this.resumes[i].isSelected) {
          this.input_sql_apply.resumes.push(this.resumes[i].resume_id);
        }
      }
      //this.input_sql_apply.updateFlag = 0;

      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/email/apply-email-template?access_token=' + store.state.token,
        data: this.input_sql_apply,
      }).then((res) => {

        console.log(res.data);
        this.getResumes(this.input_sql_apply.vacancy_id, this.input_sql_apply.funnel_stage_order_id);
        /*this.validateStatus = res.data.validateStatus;
        for (let i=0; i<this.resumes.length; i++){
          let id_run = this.resumes[i].resume_id;
          this.resumes[i].isValidated = this.validateStatus.filter(x => x.resume_id ===id_run).map(x => x.isValidated);
          console.log( 'i = ' +id_run+' ' + this.resumes[i].isValidated)
        }*/
      }).catch((err) => {
        console.error(err);
      });
    },

    sendToSelected() {


      this.input_sql_apply.user_id = store.state.user_id;
      this.input_sql_apply.resumes = [];
      for (let i = 0; i < this.resumes.length; i++) {
        if (this.resumes[i].isSelected) {
          this.input_sql_apply.resumes.push(this.resumes[i].resume_id);
        }
      }

      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/email/send-to-selected?access_token=' + store.state.token,
        data: this.input_sql_apply,
      }).then((res) => {

        console.log(res.data);
        if (res.data.status==='ok'){
          this.getResumes(this.input_sql_apply.vacancy_id, this.input_sql_apply.funnel_stage_order_id);
        }
        /*
        this.validateStatus = res.data.validateStatus;
        for (let i=0; i<this.resumes.length; i++) {
          let id_run = this.resumes[i].resume_id;
          this.resumes[i].isSent = this.validateStatus.filter(x => x.resume_id ===id_run).map(x => x.isSent);
          //console.log( 'i = ' +id_run+' ' + this.resumes[i].isValidated)
        }
        */
      }).catch((err) => {
        console.error(err);
      });


    },


  },
  data(){
    return{
      hover:false,
      activeVacancies:[],
      jobFunnels: [],
      input_save_vacancy: {
        type_id: 1,
        user_id: 1,
        vacancy_id:0,
        toDelete: false,
        name: '',
        id: 0,
        status: 0,
        funnel_stage_order_id:1,
      },
      resumes:[],
      selectAllCheckbox: false,
      emailTemplates_Options : [
        {id:1,value:'По дате добавления резюме'},
        {id:2,value:'По дате обновления резюме'},
        {id:3,value:'По убыванию зарплат'},
        {id:4,value:'По возрастанию зарплат'},
        {id:5,value:'По убыванию возраста'},
        {id:6,value:'По возрастанию возраста'},
      ],
      emailTemplates_Selected : {id:1,value:'По дате добавления резюме'},

      emailTemplates: [],


      content: '<p><strong>Обязанности:</strong></p><ul><li><br></li><li><br></li></ul><p><strong>Требования:</strong></p><ul><li><br></li><li><br></li></ul><p><strong>Условия:</strong></p><ul><li><br></li><li><br></li></ul><p><br></p>',
      editorOption: {

        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          ]
        }
      },


      selected_vacancy_id:0,
      selected_funnel_stage_order_id:0,

      input_save_template: {
        user_id: 1,
        selectForm: 0,

        template_id:1,
        template_name: '',

        from_name: '',
        from_email: '',
        subject: 'sa',
        emailBody: '',
        bcc_email: '',

        name_new: '',

        toDelete: 0,
      },

      input_sql_apply: {
        funnel_stage_order_id: 1,
        funnel_stage_name: '',

        vacancy_id: 1,
        vacancy_name: '',

        user_id: 1,
        resumes: [],
        emailTemplate: {},
        updateFlag:0,

        resume_id:1,
      },

      showEmail: {
        to_name: '',
        to_email: '',
        from_name: '',
        from_email: '',
        subject: '',
        emailBody_str: '',
      }

    }
  },


}
</script>

<style scoped>

</style>